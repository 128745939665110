<template>
  <section class="loading">
    <img src="../assets/loading.svg" alt="loading..." />
  </section>
</template>

<script>
export default {
    mounted(){
    setTimeout(() => {
      this.changeToMainRoute()}, 2500)
  },
  methods:{
    changeToMainRoute(){
      this.$router.push('/home')
      // var zeroOpacityClasses = document.getElementsByClassName('zero-opacity')
      // console.log(zeroOpacityClasses)
    }
  }
};
</script>
        
<style>
.loading {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: black;
  align-content: center;
  justify-items: center;
}
.loading img {
  width: 250px;
  margin: auto;
  animation-name: loading;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}
@keyframes loading {
  0% {
    width: 250px;
  }
  50% {
    width: 350px;
  }
  100% {
    width: 250px;
  }
}
</style>