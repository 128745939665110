<template>
  <section class="posts-depositions">
        <div class="posts">
            <div class="title-posts">
                <h1 class="title-size">posts</h1>
            </div>
            <div class="left-arrow-posts">
                <img src="../assets/left-arrow.svg" alt="<=">
            </div>
            <div class="img-posts">
                <div class="post-name">
                    <div class="foto-perfil-instagram">
                        <img src="../assets/logo.svg" alt="Foto de perfil do espaço maturité - qualidaade de vida para idosos">
                    </div>
                    <div class="post-name-location">
                        <p><b>espaco.maturite</b></p>
                        <p>Espaco Maturité - São Paulo - SP</p>
                    </div>
                    <div class="post-more-options">
                        <svg aria-label="Mais opções" fill="#262626" height="16" viewBox="0 0 48 48" width="16"><circle clip-rule="evenodd" cx="8" cy="24" fill-rule="evenodd" r="4.5"></circle><circle clip-rule="evenodd" cx="24" cy="24" fill-rule="evenodd" r="4.5"></circle><circle clip-rule="evenodd" cx="40" cy="24" fill-rule="evenodd" r="4.5"></circle></svg>
                    </div>
                </div>
                <div class="post">
                    <img src="../assets/post1.jpg" alt="" class="imgNone show">

                    <video controls id="video-box" preload="auto" class="video v14">
                      <source id="feature-image-box" src="../assets/post15.mp4">
                    </video>
                    <video controls id="video-box" preload="auto" class="video v15">
                      <source id="feature-image-box" src="../assets/post14.mp4">
                    </video>
                </div>
                
                <div botoes-post>
                        <svg class="botao-svg botao-inicial-svg" aria-label="Curtir" fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M34.6 6.1c5.7 0 10.4 5.2 10.4 11.5 0 6.8-5.9 11-11.5 16S25 41.3 24 41.9c-1.1-.7-4.7-4-9.5-8.3-5.7-5-11.5-9.2-11.5-16C3 11.3 7.7 6.1 13.4 6.1c4.2 0 6.5 2 8.1 4.3 1.9 2.6 2.2 3.9 2.5 3.9.3 0 .6-1.3 2.5-3.9 1.6-2.3 3.9-4.3 8.1-4.3m0-3c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5.6 0 1.1-.2 1.6-.5 1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path></svg>
                        <svg class="botao-svg" aria-label="Comentar" fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path clip-rule="evenodd" d="M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z" fill-rule="evenodd"></path></svg>
                        <svg class="botao-svg" aria-label="Compartilhar publicação" fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M47.8 3.8c-.3-.5-.8-.8-1.3-.8h-45C.9 3.1.3 3.5.1 4S0 5.2.4 5.7l15.9 15.6 5.5 22.6c.1.6.6 1 1.2 1.1h.2c.5 0 1-.3 1.3-.7l23.2-39c.4-.4.4-1 .1-1.5zM5.2 6.1h35.5L18 18.7 5.2 6.1zm18.7 33.6l-4.4-18.4L42.4 8.6 23.9 39.7z"></path></svg>
                        <svg class="botao-svg save-post" aria-label="Salvar" fill="#262626" height="24" viewBox="0 0 48 48" width="24"><path d="M43.5 48c-.4 0-.8-.2-1.1-.4L24 29 5.6 47.6c-.4.4-1.1.6-1.6.3-.6-.2-1-.8-1-1.4v-45C3 .7 3.7 0 4.5 0h39c.8 0 1.5.7 1.5 1.5v45c0 .6-.4 1.2-.9 1.4-.2.1-.4.1-.6.1zM24 26c.8 0 1.6.3 2.2.9l15.8 16V3H6v39.9l15.8-16c.6-.6 1.4-.9 2.2-.9z"></path></svg>
                </div>
                <div class="post-caption">
                    <div>
                        <img src="../assets/logo.svg" alt="Foto de perfil do espaço maturité - qualidaade de vida para idosos">
                        <p>Curtido por <b>espaco.maturite</b> e <b>outras pessoas</b></p>
                    </div>
                    <p><b>espaco.maturite</b> No nosso espaço, os idosos praticam atividades físicas, melhorando sua saúde e porte físico.</p>
                </div>
                <div class="post-time">
                    <p>HÁ ALGUM TEMPO</p>
                </div>
            </div>
            <div class="right-arrow-posts">
                <img src="../assets/right-arrow.svg" alt="=>">
            </div>
        </div>
        <div class="depositions">
            <h1 class="title-size">depoimentos</h1>
            <p></p>
                <div class="botoes-depositions">

                </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'Posts-depoimentos',
    mounted: () => {
      const numberOfPosts = 15
      const numberOfVideo = 2

      const depoimentos = [
        "(VICTÓRIA)<br>QUE DIA AGRADÁVEL, DANCEI, PLANTEI TEMPEROS EM VASINHOS, FIZ CROCHÊ COM NOVAS AMIGAS, EXERCÍCIOS. <br> QUE PENA O TEMPO PASSOU MUITO RÁPIDO.",
        "(WLADIMIR)<br>TARDE FELIZ NESSE ESPAÇO. <br> ÓTIMO ACOLHIMENTO  COM DIVERSAS ATIVIDADES SUPERVISIONADAS E NOVOS AMIGOS.",
        "(FRANCISCA)<br>COMO É BOM OCUPARMOS AS NOSSAS MENTES! <br> ADORO! <br> CONTINUEM FIRMES!!!",
        "(MARIA)<br>AS ATIVIDADES PROPOSTAS PELO MATURITE,DEMONSTRAM ATRAVÉS DAS RELAÇÕES DE AFETO, O VIVER CADA SEGUNDO.",
        "(Rubens & Elza)<br>Para mim a participação no Grupo Maturité é muito importante. Além da Equipe Maturité: Bethe, Denise e Katia serem extremamente carinhosas, delicadas e criativas, nos proporcionam uma aproximação com outras moradoras do condomínio, que me eram estranhas. Funciona para mim como uma terapia. Não nos envolvendo com o cotidiano...",
        "(Rubens & Elza 2)<br>Passo momentos de relaxamento, com exercícios e aprendizados de coisas que jamais pensei em participar, como é o caso dos instrumentos musicais. Também as palestra de profissionais sobre assuntos de interesse geral. Acho muito eficaz. Não sei se estou muito extensa, mas preciso dizer o bem que a participação no grupo me faz. É uma pena que mais pessoas não aderiram os encontros com Maturité, pois além de descontraídos encerram com bolo, chá."
      ]
      
      const buttons = []

      depoimentos.forEach(depoimento => {
        const button = document.createElement('button')
        buttons.push(button)
        button.addEventListener('click', () => {
          document.querySelector("#page > section.posts-depositions > div.depositions > p").innerHTML = depoimento
          buttons.forEach(btn => {
            btn.className = ''
          })
          button.className = 'btn-deposition-active'
        })
        document.querySelector("#page > section.posts-depositions > div.depositions > div").appendChild(button)
      })

      buttons[0].click()

      const initialIndex = 1
      const imageComponent = document.querySelector("#page > section.posts-depositions > div.posts > div.img-posts > div.post > img")
      const videoComponent = document.querySelector("#page > section.posts-depositions > div.posts > div.img-posts > div.post > video.v14")
      const videoComponent2 = document.querySelector("#page > section.posts-depositions > div.posts > div.img-posts > div.post > video.v15")
      const forwardComponent = document.querySelector("#page > section.posts-depositions > div.posts > div.right-arrow-posts > img")
      const backwardComponent = document.querySelector("#page > section.posts-depositions > div.posts > div.left-arrow-posts > img")
      var postIndex = 1

      function imageForward(){
        if (postIndex < numberOfPosts){
          postIndex++;
        }
        else {
          postIndex = initialIndex;
        }        

        changeMidia()
      }

      function imageBackward(){        

        if (postIndex > initialIndex){
          postIndex--;
        }
        else {
          postIndex = numberOfPosts;
        }

        changeMidia()
        
      }

      function changeMidia(){
        if (postIndex <= numberOfPosts - numberOfVideo){
          updateImage(postIndex)
        }
        else {
          updateVideo(postIndex)
        }
      }

      async function updateImage(index){
        videoComponent.classList.remove('show')
        videoComponent2.classList.remove('show')
        videoComponent.pause()
        videoComponent2.pause()
        imageComponent.classList.add('show')
        imageComponent.src = require(`../assets/post${index}.jpg`)
      }

      async function updateVideo(index){
        imageComponent.classList.remove('show')
        
        var path = 'v'+ String(index) 

        var video = document.getElementsByClassName(path)[0];

        var v1 = document.getElementsByClassName('v14')[0];
        var v2 = document.getElementsByClassName('v15')[0];

        v1.classList.remove('show');
        v2.classList.remove('show');

        v1.pause();
        v2.pause();
      
        video.play();

        video.classList.add('show');
      }      

      forwardComponent.addEventListener('click', imageForward)
      backwardComponent.addEventListener('click', imageBackward)

    }
}
</script>

<style>

.posts-depositions {
    width: 100vw;
    background-color: black;
    display: grid;
    justify-content: center;
    padding: 70px 0;
    grid-template-areas: "posts depositions";
  }

  .imgNone{
    display: none;
  }

  .video {
    display: none;
    height: 800px;
    max-width: 820px;
  }

  .show{
    display: block;
  }
  
  .posts {
    max-width: 820px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #262626;
    display: grid;
    grid-template-areas:
      "title title title"
      "left image right";
    justify-content: center;
    align-items: center;
    padding-right: 40px;
    border-right: 3px dashed var(--orange-color);
    grid-area: posts;
  }
  
  .posts h1 {
    text-align: center;
    margin-bottom: 50px;
  }
  .title-posts {
    color: var(--orange-color);
    font-family: var(--font-title);
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    grid-area: title;
  }
  .left-arrow-posts {
    grid-area: left;
    padding-right: 20px;
  }
  .right-arrow-posts {
    padding-left: 20px;
    grid-area: right;
  }
  .img-posts {
    background-color: white;
    padding: 0 0 10px 0;
    grid-area: image;
  }
  .post-name {
    display: flex;
    padding: 5px;
    align-items: center;
  }
  .post img {
    max-width: 455px;
    max-height: 705px;
  }
  .post-caption {
    max-width: 435px;
  }
  .post-caption div {
    display: flex;
    align-items: center;
  }
  .post-caption div img {
    width: 20px;
    padding: 3px 0;
    border: 1px solid var(--orange-color);
    border-radius: 70px;
  }
  .post-caption {
    margin-left: 10px;
  }
  .post-caption div p {
    margin-left: 5px;
  }
  .foto-perfil-instagram img {
    border: 2px solid var(--orange-color);
    width: 40px;
    padding: 5px 0;
    border-radius: 50px;
  }
  .post-name-location {
    margin-left: 10px;
  }
  .post-name-location p:last-child {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
  }
  .post-more-options {
    padding-left: 190px;
  }
  .post-time {
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
    color: #8e8e8e;
    margin-left: 10px;
  }
  .botoes-post {
    width: 100%;
  }
  .botao-inicial-svg {
    margin-left: 10px;
  }
  .botao-svg {
    margin-top: 5px;
    margin-right: 7px;
  }
  .save-post {
    margin-left: 305px;
  }
  @media screen and  (min-width:600px) and (max-width:1200px){
    .posts-depositions{
      grid-template-areas: 
      "posts"
      "depositions";
      justify-content: center;
    }
    .left-arrow-posts img {
      width: 30px;
    }
    .right-arrow-posts img{
      width: 30px;
    }
    .posts{
      border-right: 0;
      padding: 0;
      margin-bottom: 50px;
      margin-left: 0;
    }
  }
  @media screen and  (min-width:320px) and (max-width:599px){
    .posts-depositions{
      grid-template-areas: 
      "posts"
      "depositions";
      justify-content: center;
      
    }
    .title-posts h1{
      font-size: 40px;
    }
    .img-posts{
      max-width: 280px;
    }
    .post img{
      max-width: 280px;
    }
    .post-more-options {
      padding-left: 40px;
    }
    .save-post {
      margin-left: 130px;
    }
    .left-arrow-posts img {
      width: 30px;
    }
    .right-arrow-posts img{
      width: 30px;
    }
    .posts{
      border-right: 0;
      padding: 0;
      margin-bottom: 50px;
    }
  }
  /* DEPOIMENTOS */
  
  .depositions {
    display: flex;
    flex-direction: column;
    max-width: 820px;
    font-family: "Times New Roman", Times, serif;
    color: #707070;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    grid-area: depositions;
    margin-left: 0px;
    justify-content: space-between;
    
  }
  .depositions p{
    margin: 0 10px;
    width: 100%
  }
  .depositions h1 {
    font-family: var(--font-title);
    color: var(--orange-color);
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  .depositions button {
    background-color: white;
    height: 30px;
    width: 30px;
    margin: 25px 10px 0 10px;
    border-radius: 15px;
  }
  
  .depositions button:hover {
    background-color: var(--orange-color);
  }
  
  .botoes-depositions .btn-deposition-active {
    background-color: var(--orange-color);
  }
  
  @media screen and  (min-width:320px) and (max-width:1000px){
    .depositions{
      justify-self: center;
      justify-content: center;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      padding: 0 30px;
    }
  }


</style>