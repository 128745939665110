<template>
  <div>
      <div id="page" key="main-page">
        <DownHeader />
        <Sobre />
        <Posts />
        <Parceiros />
        <Planos />
        <Caracteristicas />
        <Contato />
      </div>
  </div>
</template>

<script>
import DownHeader from "./Down-header.vue";
import Posts from "./Posts-depoimentos.vue";
import Sobre from "./Sobre";
import Contato from "./Contato.vue";
import Planos from "./Planos.vue";
import Caracteristicas from "./Caracteristicas.vue";
import Parceiros from "./Parceiros.vue";

export default {
  name: "Main-page",
  components: {
    DownHeader,
    Sobre,
    Posts,
    Parceiros,
    Contato,
    Planos,
    Caracteristicas
  }//mounted() {
    //window.addEventListener("load", () => this.activateTransition());
  //},
  //destroyed() {
    //window.removeEventListener("load", () => this.activateTransition());
  //},
  //data() {
   // return {
    //  pageMounted: true,
    //};
  //},
  /*methods: {
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    /*activateTransition() {
      let image = document.querySelector("img");
      console.log(image);
      let isLoaded = image.complete && image.naturalHeight;
      this.wait(1000);
      if (isLoaded) this.pageMounted = true;
      else {
        this.wait(1000);
        this.pageMounted = true;
      }
    },
  },*/
};
</script>
<style>
/*.v-enter-active,
.v-leave-active {
  transition: opacity 2s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}*/

#initial-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
}

#initial-logo img {
  position: fixed;
  height: 50vw;
  width: 50vw;

  left: 50%;
  margin-left: -25vw;

  top: 50%;
  margin-top: -25vw;
}
</style>
