<template>
  <section id="features">
    <h1 class="title-size" style="color: var(--orange-color)">PROPOSTAS</h1>
    <div class="feature-main-box">
      <div id="feature-image-box" class="jardinagem-bk"></div>
      <div class="box-btn">
        <div :class="['feature-btn', 'size-text', 'cancel-right', selectedBtn == 0 ? 'selected-btn' : '']" @click="mudaFoto(0, 'jardinagem')">Jardinagem</div>
        <div :class="['feature-btn', 'size-text', 'cancel-right', selectedBtn == 1 ? 'selected-btn' : '']" @click="mudaFoto(1, 'culinaria')">Culinária</div>
        <div :class="['feature-btn', 'size-text', this.selectedBtn == 2 ? 'selected-btn' : '']" @click="mudaFoto(2, 'musicoterapia')">Musicoterapia</div>
      </div>
      <div class="box-btn">
        <div :class="['feature-btn', 'size-text', 'cancel-right', selectedBtn == 3 ? 'selected-btn' : '']" @click="mudaFoto(3, 'palestras')">Palestras</div>
        <div :class="['feature-btn', 'size-text', selectedBtn == 4 ? 'selected-btn' : '']" @click="mudaFoto(4, 'esportes')">Atividade Física</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Caracteristicas",
  data() {
    return{
      selectedBtn: 0
    }
  },
  methods: {
    mudaFoto(btn, foto){
      this.selectedBtn = btn;
      document.getElementById('feature-image-box').className = (foto + '-bk')
    }
  }
}
</script>

<style>
.jardinagem-bk{
  transition: background 0.5s;
  background: black url('../assets/jardinagem.jpeg') no-repeat center;
  background-size: contain;
}

.culinaria-bk{
  transition: background 0.5s;
  background: black url('../assets/culinaria.jpeg') no-repeat center;
  background-size: contain;
}

.musicoterapia-bk{
  transition: background 0.5s;
  background: black url('../assets/musicoterapia.jpeg') no-repeat center;
  background-size: contain;
}

.palestras-bk{
  transition: background 0.5s;
  background: black url('../assets/palestras.jpeg') no-repeat center;
  background-size: contain;
}

.esportes-bk{
  transition: background 0.5s;
  background: black url('../assets/atividade-fisica.jpeg') no-repeat center;
  background-size: contain;
}

#features {
  width: 100vw;
  height: 63vw;
  background: var(--background-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.title-size{
  font-family: var(--font-title);
}
.feature-main-box{
  width: 60vw;
  height: 40vw;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#feature-image-box{
  flex-grow: 10;
}

.box-btn{ 
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.box-btn{
  
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.feature-btn:hover{
  cursor: pointer;
  background: var(--orange-color);
  transition: background 1s;
}

.feature-image-box + .box-btn .feature-btn{
  border-bottom: none;
}

.feature-btn{
  box-sizing: border-box;
  font-family: var(--font-title);
  border: 0.52vw solid white;
  display: inherit;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.selected-btn{
  background-color: var(--orange-color);
}

.cancel-right{
  border-right: none;  
}

@media screen and  (max-width: 600px){
  .feature-btn{
    font-size: 4vw;
  }
}

@media screen and  (max-width: 600px){
  .feature-btn{
    font-size: 3vw;
  }
}

</style>