<template>
  <div id="parceiros">
    <h1 class="title-size" style="color: var(--orange-color)">NOSSOS PARCEIROS</h1>
    <div class="feature-main-box">
      <video controls id="video-box" preload="auto" class="videoScreen show v0">
        <source id="feature-image-box" src="../assets/Parceiro1.mp4">
      </video>
      <video controls id="video-box" preload="auto" class="videoScreen v1">
        <source id="feature-image-box" src="../assets/Parceiro2.mp4">
      </video>
      <video controls id="video-box" preload="auto" class="videoScreen v2">
        <source id="feature-image-box" src="../assets/Parceiro3.mp4">
      </video>
      <div class="box-btn">
        <button :class="['feature-btn', 'size-text', 'cancel-right', selectedBtn == 0 ? 'selected-btn' : '']" @click="mudaVideo(0)">Diogo Presuntinho</button>
        <button :class="['feature-btn', 'size-text', 'cancel-right', selectedBtn == 1 ? 'selected-btn' : '']" @click="mudaVideo(1)">Deise Alves</button>
        <button :class="['feature-btn', 'size-text', this.selectedBtn == 2 ? 'selected-btn' : '']" @click="mudaVideo(2)">Fernanda Marques</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return{
      selectedBtn: 0
    }
  },
  methods: {
    mudaVideo(btn){
      this.selectedBtn = btn;      

      this.loadVideo('v'+ String(btn)); 

    },

    loadVideo(path){

      var video = document.getElementsByClassName(path)[0];

      var v1 = document.getElementsByClassName('v0')[0];
      var v2 = document.getElementsByClassName('v1')[0];
      var v3 = document.getElementsByClassName('v2')[0];

      v1.classList.remove('show');
      v2.classList.remove('show');
      v3.classList.remove('show');

      v1.pause();
      v2.pause();
      v3.pause();
    
      video.play();

      video.classList.add('show');
    }
  }
}
</script>

<style>

#features {
  width: 100vw;
  height: 63vw;
  background: var(--background-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.title-size{
  font-family: var(--font-title);
}

#parceiros{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 105vh;
  margin: 0;
  padding: 0;
  background-color: var(--background-gray);
}

.feature-main-box{
  width: 60vw;
  height: 40vw;
  margin: 0vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#feature-image-box{
  flex-grow: 10;
  height: 10vw;
}

.box-btn{ 
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.videoScreen {
  display: none;
}

.show{
  display: block;
}

.box-btn{
  
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
}

.feature-btn:hover{
  cursor: pointer;
  background: var(--orange-color);
  transition: background 1s;
}

.feature-image-box + .box-btn .feature-btn{
  border-bottom: none;
}

#video-box{
  
  height: 40vw;
  margin: 10px 0px 5px;
  width: 100%;
}

.feature-btn{
  box-sizing: border-box;
  font-family: var(--font-title);
  border: 0.52vw solid white;
  display: inherit;
  height: 90%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.selected-btn{
  background-color: var(--orange-color);
}

.cancel-right{
  border-right: none;  
}

@media screen and  (max-width: 600px){
  .feature-btn{
    font-size: 4vw;
  }
}

@media screen and  (max-width: 600px){
  .feature-btn{
    font-size: 3vw;
  }
}

</style>