<template>
  <div id="app">
    <div class="zero-opacity"><Header-maturite/></div>
      <transition
        name="fade"
        mode="out-in"
      >
      <keep-alive>
        <router-view/>
      </keep-alive>
      </transition>
      <div class="zero-opacity"><Footer-matutite/></div>
  </div>
</template>

<script>
import FooterMatutite from './components/Footer-matutite.vue'
import HeaderMaturite from './components/Header-maturite.vue'

export default {
  components: { HeaderMaturite, FooterMatutite },  
  watch:{
    '$route' (){
       parent.window.scrollTo(0,0);
    }
}
}
</script>

<style>

  .div-to-appear-in-loaded{
    opacity: 0;
    transition: 0.5s opacity ease-in-out;
}
  .fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>