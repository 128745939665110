<template>
  <footer>
    <h6>Copyright © 2021 - Espaço Maturité - Todos os direitos reservados</h6>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
footer{
    width: 100vw;
    height: 68px;
    background-color: var(--background-gray);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
}

footer h6{
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    margin-left: 7.3vw;
}

</style>