import Vue from 'vue'
import VueRouter from 'vue-router'
import InitialLoad from '../components/InitialLoad.vue'
import MainPage from '../components/Main-page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/sobre',
    name: 'About',
    component: () => import('../components/Sobre-Main.vue')
  },
  {
    path: '/contato',
    name: 'Contact',
    component: () => import('../components/contact.vue')
  },
  {
    path: '*',
    name: 'Load',
    component: InitialLoad
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
